:host {
    --ol-control-radius: var(--default-radius);
    --ol-control-background-color: rgba(200, 200, 200, 0.5);
    --ol-control-button-text-color: #555;
    --ol-control-button-background-color: #ffffff;
    --ol-control-button-text-color-hover: #000000;
    --ol-control-button-background-color-hover: #eeeeee;
    --ol-control-button-text-color-active: #000000;
    --ol-control-button-background-color-active: #dddddd;
    --ol-control-attribution-text-color: #555555;
    --ol-control-attribution-background-color: #fff;
    --ol-control-gap: .75em;
    --map-height: 500px;

    display: block;
    height: var(--map-height);
    background-color: #555;

    @extend .oag-map;
}