:host {
    --version-text-color: #000000;
    --version-background-color: #f4f4f4;
    --date-text-color: #777777;
    display: flex;
    flex-direction: row;
    gap: 2em;
    margin-top: 3em;

    .meta {
        width: 180px;
        border-top: 2px #ddd solid;
        padding: 1em;
        padding-left: 0;
        margin-top: 8px;
        display: inline-flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.75em;

        &__date {
            color: var(--date-text-color);
        }

        &__version {
            padding: 0.3em 0.6em;
            color: var(--version-text-color);
            background-color: var(--version-background-color);
            border-radius: var(--default-radius);
            line-height: 1;
            font-weight: 600;
            font-size: 0.9em;
            letter-spacing: 0.5px;
        }
    }

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 2em;
        text-align: justify;
    }
}