:host {
    --layout-padding: var(--default-layout-padding);
    --layout-inner-max-width: var(--default-layout-inner-max-width);
    --layout-outer-max-width: var(--default-layout-outer-max-width);
    --control-padding: var(--default-component-padding);

    width: 100%;
    display: block;
    padding: 0 var(--layout-padding);

    .container {
        margin: 0px auto;
        display: flex;
        flex-direction: row;
        max-width: var(--layout-outer-max-width);

        .control {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: var(--control-padding);

            button {
                padding: 0.8em;
                border: 0;
                line-height: 0;
                border-radius: 999em;
                background-color: #999;
                transition: all ease 300ms;

                svg {
                    width: 1.35em;
                    height: 1.35em;

                    path {
                        stroke: #ccc;
                    }
                }

                &[disabled] {
                    opacity: 0.5;
                }

                &:hover:not([disabled]) {
                    cursor: pointer;
                    background-color: #ddd;

                    svg {
                        path {
                            stroke: #000;
                        }
                    }
                }
            }
        }

        .content {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow: hidden;
            max-width: var(--layout-inner-max-width);

            oag-figure {
                padding: 0;
            }
        }
    }
}