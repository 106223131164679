:host {
    --section-gap-height: var(--default-layout-padding);
    --font-family: var(--default-text-font-family);
    --base-font-family: 16px;
    --information-padding: var(--default-layout-padding);
    --information-background-color: #fff;

    @include global-buttons;

    display: flex;
    flex-direction: column;
    gap: var(--section-gap-height);
    overflow: auto;
    box-sizing: border-box;
    font-family: var(--font-family);
    font-size: var(--base-font-family);

    .information {
        box-sizing: border-box;
        position: fixed;
        z-index: 99999;
        justify-content: center;
        align-items: center;
        display: none;
        flex-direction: column;
        height: 100%;
        width: 100%;
        background-color: var(--information-background-color);
        padding: var(--information-padding);
        text-align: center;
    }
}


@media screen and (max-width: $phoneBreakpoint) {

    :host([desktop-only="true"]),
    :host([desktop-optimized="true"]:not(.confirmed)) {
        .information {
            display: flex;
        }
    }
}