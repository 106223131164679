:host {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1,
    h3 {
        font-size: 1.5em;
        letter-spacing: 1px;
        font-weight: bold;
        font-family: 'Jost', Helvetica, sans-serif;
        margin-top: 2em;
        color: #000;
    }

    h3 {
        text-transform: uppercase;
        font-size: 1em;
        letter-spacing: 1px;
        margin-top: 1em;
        color: #777;
    }

    .spinner {
        border: 24px solid;
        border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: animloader 1s linear infinite;
    }

    @keyframes animloader {
        0% {
            border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.75);
        }

        33% {
            border-color: rgba(0, 0, 0, 0.75) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.35);
        }

        66% {
            border-color: rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.75) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25);
        }

        100% {
            border-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.75) rgba(0, 0, 0, 0.15);
        }
    }
}