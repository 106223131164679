:host {
    --layout-padding: var(--default-layout-padding);
    --layout-inner-max-width: var(--default-layout-inner-max-width);
    --layout-outer-max-width: var(--default-layout-outer-max-width);

    width: 100%;
    display: block;
    padding: 0 var(--layout-padding);
    box-sizing: border-box;

    .container {
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        max-width: var(--layout-inner-max-width);
    }
}

:host([max-width=outer]) {
    .container {
        max-width: var(--layout-outer-max-width);
    }
}

:host([max-width=full]) {
    .container {
        max-width: 100%;
    }
}