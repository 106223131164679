:host {
    @include screen-content-item-base;

    flex-direction: row;
    gap: 0.5em;
    margin-bottom: 1em;

    svg {
        width: 1rem;
        height: 1rem;
    }

    span {
        line-height: 1;
    }
}

:host(:hover) {
    @include screen-content-item-hover-base;
}