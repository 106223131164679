:host {
    display: flex;
    flex-direction: column;

    h4 {
        margin: 0;
        padding: 0;
        font-size: 1.15em;
        font-weight: 600;
    }

    .content {
        display: block;
    }

    .tags {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 0.5em;

        .tag {
            padding: 0.5em 0.8em;
            background-color: #f4f4f4;
            border-radius: var(--default-radius);
            line-height: 1;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 0.65em;
            letter-spacing: 0.5px;
        }
    }
}