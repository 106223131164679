:host {
    --background-color: #f4f4f4;
    --background-color-highlight: #FFFFCC;
    --intent: 2em;

    display: inline-flex;
    flex-direction: column;
    background-color: var(--background-color);
    padding: 0.75em 1em;
    border-radius: var(--default-radius);
    margin-bottom: 0.5em;
    scroll-margin-top: 100px;

    & .slot-content {
        color: #000;
        font-size: 1em;
    }

    .label {
        color: #000;
        font-size: 1em;
        margin-bottom: 0.15em;
    }

    .reference-code {
        color: #555;
        font-size: 0.8em;
    }
}

:host(:target) {
    background-color: var(--background-color-highlight);
}

:host([content="literatur"]) {
    .slot-content {
        text-indent: calc(-1 * var(--intent));
        padding-left: var(--intent);
    }
}